* {
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.parent-container {
  width: 100%;
}

.container-wrapper {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gallery {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.cards {
  padding-left: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.cards li {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 2rem;
  padding: 0px 40px;
}

.box {
  height: 300px;
  min-width: 400px;
  max-width: 400px;
  box-shadow: 10px 25px 50px -12px rgb(0 0 0 / 0.75);
}

.card-heading {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  text-transform: uppercase;
}

.image-wrapper {
  height: 260px;
  width: 400px;
  object-fit: cover;
}

@keyframes wave {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-20px) translateX(calc(10px * var(--direction)));
  }
}
